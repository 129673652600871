import { createContext, useState } from "react";
import Step from "./components/Step";
import cross from "../assets/cross.png";
export const FormContext = createContext();

function App() {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [formData, setFormData] = useState({});

  return (
    <FormContext.Provider
      value={{ activeStepIndex, setActiveStepIndex, formData, setFormData }}
    >
      <div className="form-wrapper">
        <button
          onClick={(e) => closeForm(e)}
          type="button"
          className="absolute top-0 right-0 translate-y-4 -translate-x-4"
        >
          <img src={cross} />
        </button>
        <Step />
      </div>
    </FormContext.Provider>
  );
}

function closeForm(e) {
  const form = e.target.closest("#start-a-project-form");
  form.setAttribute("data-form-open", false);
}

export default App;

import { useContext } from "react";
import { FormContext } from "../App";
import ChevronLeft from "./ChevronLeft";
import ChevronRight from "./ChevronRight";

function Pagination({ step }) {
  const { activeStepIndex } = useContext(FormContext);
  return (
    <div className="flex flex-col justify-center items-center gap-8 mt-6 lg:flex-row lg:mt-auto">
      <div className="flex items-center gap-2">
        <ChevronLeft />
        <ChevronRight />
      </div>
      <p className="font-bold uppercase tracking-wider text-base w-[400px] px-4 text-center lg:text-left">
        {`Step ${activeStepIndex} of 13: ${step}`}
      </p>
    </div>
  );
}

export default Pagination;

import { Form, Field, Formik } from "formik";
import React, { useContext } from "react";
import { FormContext } from "../App";
import Input from "../components/Input";
import Pagination from "../components/Pagination";
import Heading from "../components/Heading";

function DesignStyle() {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext);

  return (
    <>
      <Heading />
      <p className="text-sm -translate-y-6 lg:w-[55%] mx-auto">
        Your bespoke home will be designed specifically for you. To indicate
        your preferred style, we encourage you to select one or more of these
        images.
      </p>
      <Formik
        initialValues={{
          houseStyle: formData.houseStyle ?? "",
          houseStyleOther: formData.houseStyleOther ?? "",
        }}
        onSubmit={(values) => {
          const data = { ...formData, ...values };
          setFormData(data);
          setActiveStepIndex(activeStepIndex + 1);
        }}
      >
        <Form className="flex flex-col gap-6 h-full justify-center">
          <div className="grid grid-cols-2 gap-4 mx-auto px-4 lg:gap-x-8 lg:gap-y-4 lg:w-[55%] lg:px-0 lg:grid-cols-3">
            <div className="flex flex-col gap-2">
              <img src="/wp-content/uploads/2022/10/Style-Country-1.jpg" />
              <label className="flex items-center gap-2 text-base flex-shrink-0 flex-wrap">
                <Field
                  type="checkbox"
                  name="houseStyle"
                  value="Australian Country"
                />
                Australian Country
              </label>
            </div>
            <div className="flex flex-col gap-2">
              <img src="/wp-content/uploads/2022/10/Style-Hamptons-1.jpg" />
              <label className="flex items-center gap-2 text-base flex-shrink-0 flex-wrap">
                <Field type="checkbox" name="houseStyle" value="Hamptons" />
                Hamptons
              </label>
            </div>
            <div className="flex flex-col gap-2">
              <img src="/wp-content/uploads/2022/10/Style-Coastal-1.jpg" />
              <label className="flex items-center gap-2 text-base flex-shrink-0 flex-wrap">
                <Field type="checkbox" name="houseStyle" value="Coastal" />
                Coastal
              </label>
            </div>
            <div className="flex flex-col gap-2">
              <img src="/wp-content/uploads/2022/10/Style-Midcentury-1.jpg" />
              <label className="flex items-center gap-2 text-base flex-shrink-0 flex-wrap">
                <Field
                  type="checkbox"
                  name="houseStyle"
                  value="Mid Century Modern"
                />
                Mid Century Modern
              </label>
            </div>
            <div className="flex flex-col gap-2">
              <img src="/wp-content/uploads/2022/10/Style-Contemporary-1.jpg" />
              <label className="flex items-center gap-2 text-base flex-shrink-0 flex-wrap">
                <Field type="checkbox" name="houseStyle" value="Contemporary" />
                Contemporary
              </label>
            </div>
            <div className="flex flex-col gap-2">
              <img src="/wp-content/uploads/2022/10/Style-Industrial-1.jpg" />
              <label className="flex items-center gap-2 text-base flex-shrink-0 flex-wrap">
                <Field type="checkbox" name="houseStyle" value="Industrial" />
                Industrial
              </label>
            </div>
          </div>
          <div className="flex items-center justify-center mx-auto lg:w-[55%]">
            <Input label="Other" name="houseStyleOther" />
          </div>
          <Pagination step="Design Style" />
        </Form>
      </Formik>
    </>
  );
}

export default DesignStyle;

import { Field } from "formik";

const Radio = ({ name, value, type = "radio" }) => {
  return (
    <label className="flex items-center gap-2 text-base flex-shrink-0 flex-wrap">
      <Field type={type} name={name} value={value} />
      {value}
    </label>
  );
};

export default Radio;

import { Form, Formik } from "formik";
import React, { useContext } from "react";
import { FormContext } from "../App";
import Input from "../components/Input";
import RadioGroup from "../components/RadioGroup";
import Radio from "../components/Radio";
import Pagination from "../components/Pagination";
import Heading from "../components/Heading";

function Bathrooms() {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext);

  return (
    <>
      <Heading />
      <Formik
        initialValues={{
          masterEnsuite: formData.masterEnsuite ?? "",
          minorBathrooms: formData.minorBathrooms ?? "",
          guestEnsuite: formData.guestEnsuite ?? "",
          laundryStorage: formData.laundryStorage ?? "",
          powderRoom: formData.powderRoom ?? "Yes",
        }}
        onSubmit={(values) => {
          const data = { ...formData, ...values };
          setFormData(data);
          setActiveStepIndex(activeStepIndex + 1);
        }}
      >
        <Form className="flex flex-col gap-6 h-full justify-center w-full max-w-5xl">
          <Input
            label={"Preferences for master ensuite"}
            name="masterEnsuite"
          />
          <Input
            label={"Preferences for minor bathrooms(s)"}
            name="minorBathrooms"
          />
          <Input
            label={"Preferences for guest ensuite (if applicable)"}
            name="guestEnsuite"
          />
          <Input
            label={"Preferences for laundry + linen cupboard/storage"}
            name="laundryStorage"
          />
          <RadioGroup label={"Would you like a powder room?"}>
            <Radio name="powderRoom" value="Yes" />
            <Radio name="powderRoom" value="No" />
          </RadioGroup>
          <Pagination step="Bathrooms/Laundry" />
        </Form>
      </Formik>
    </>
  );
}

export default Bathrooms;

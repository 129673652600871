import { Field, Form, Formik } from "formik";
import React, { useContext } from "react";
import { FormContext } from "../App";
import Radio from "../components/Radio";
import Pagination from "../components/Pagination";
import Heading from "../components/Heading";

function Features() {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext);

  return (
    <>
      <Heading />
      <Formik
        initialValues={{
          designFeatures: formData.designFeatures ?? "",
          specificationLevel:
            formData.specificationLevel ??
            "Looking for an elegant fit-out within a set budget",
        }}
        onSubmit={(values) => {
          const data = { ...formData, ...values };
          setFormData(data);
          setActiveStepIndex(activeStepIndex + 1);
        }}
      >
        <Form className="flex flex-col gap-4 w-full justify-center mx-auto lg:gap-12 lg:max-w-4xl lg:h-full">
          <label className="flex flex-col flex-wrap gap-1">
            Are there any specific design details or architectural features you
            would like to see in the home?
            <Field
              name="designFeatures"
              className="w-full border-t-0 border-l-0 border-r-0 border-b border-black pb-px"
            />
          </label>

          <div className="flex flex-col gap-6">
            <span className="flex-shrink-0">
              Which of these best describes the level of specification desired?
            </span>
            <Radio
              name="specificationLevel"
              value="Looking for an elegant fit-out within a set budget"
            />
            <Radio
              name="specificationLevel"
              value="Looking to upgrade to specific luxury features"
            />
            <Radio
              name="specificationLevel"
              value="Looking for the very best throughout the home"
            />
          </div>
          <Pagination step="Features" />
        </Form>
      </Formik>
    </>
  );
}

export default Features;

import { useEffect, useContext } from "react";
import { FormContext } from "../App";
import Heading from "../components/Heading";

function ThankYou() {
  const { formData } = useContext(FormContext);

  /**
   * Send the data to the AJAX handler
   * @param {object} formData
   */
  useEffect(() => {
    // Setup the form data for the request
    const data = new FormData();
    for (const [key, value] of Object.entries(formData)) {
      data.append(key, value);
    }

    fetch("/wp-admin/admin-ajax.php?action=handle_form_submission", {
      method: "POST",
      body: data,
    });
  }, []);

  return (
    <>
      <div className="flex flex-col items-center justify-center h-full">
        <Heading text="Thank you!" />
        <p className="text-xl">
          We'll be in touch soon. If you have any questions in the meantime,
          don't hesitate to <a href="/contact">contact us.</a>
        </p>
      </div>
    </>
  );
}

export default ThankYou;

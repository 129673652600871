import { Field, Form, Formik } from "formik";
import React, { useContext } from "react";
import { FormContext } from "../App";
import Radio from "../components/Radio";
import Pagination from "../components/Pagination";
import Heading from "../components/Heading";

function Logistics() {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext);

  return (
    <>
      <Heading />
      <Formik
        initialValues={{
          budget: formData.budget ?? "Under $500,000",
          preferredTimeline: formData.preferredTimeline ?? "",
        }}
        onSubmit={(values) => {
          const data = { ...formData, ...values };
          setFormData(data);
          setActiveStepIndex(activeStepIndex + 1);
        }}
      >
        <Form className="flex flex-col gap-12 h-full justify-center max-w-4xl mx-auto w-full">
          <div className="flex flex-col gap-6">
            <span className="flex-shrink-0 text-center">
              Which of these best describes your approximate budget?
            </span>
            <div className="flex flex-col flex-wrap gap-6 justify-center lg:flex-row">
              <Radio name="budget" value="Under $500,000" />
              <Radio name="budget" value="$500,000 - $750,000" />
              <Radio name="budget" value="$750,000 - $1,000,000" />
              <Radio name="budget" value="$1,000,000 - $1,250,000" />
              <Radio name="budget" value="$1,250,000 - $1,500,000" />
              <Radio name="budget" value="$1,500,000+" />
            </div>
          </div>
          <label className="flex flex-col gap-1 max-w-4xl mx-auto w-full text-center">
            What is your preferred timeline?
            <Field
              name="preferredTimeline"
              className="w-full border-t-0 border-l-0 border-r-0 border-b border-black pb-px"
              required
            />
          </label>
          <Pagination step="Logistics" />
        </Form>
      </Formik>
    </>
  );
}

export default Logistics;

const RadioGroup = ({ label, children }) => {
  return (
    <div className="flex flex-wrap justify-center items-center gap-4 px-4 lg:gap-12 lg:px-0 lg:justify-start">
      <span className="flex-shrink-0 text-center lg:text-left w-full font-bold lg:font-normal lg:w-fit">
        {label}
      </span>
      {children}
    </div>
  );
};

export default RadioGroup;

import { FormContext } from "../App";
import React, { useContext } from "react";

const ChevronLeft = () => {
  const { activeStepIndex, setActiveStepIndex } = useContext(FormContext);
  return (
    <button
      type="button"
      onClick={() => setActiveStepIndex(activeStepIndex - 1)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        className="w-10 h-10 p-3 rounded-full border border-black hover:text-white hover:bg-black cursor-pointer"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15.75 19.5L8.25 12l7.5-7.5"
        />
      </svg>
    </button>
  );
};

export default ChevronLeft;

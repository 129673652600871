import { Form, Formik } from "formik";
import React, { useContext } from "react";
import { FormContext } from "../App";
import Pagination from "../components/Pagination";
import Input from "../components/Input";

function ContactDetails() {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext);

  return (
    <>
      <div className="text-center flex flex-col gap-2">
        <h3>Design Brief Complete</h3>
        <h2 className="normal-case tracking-normal text-4xl lg:text-7xl hidden lg:block">
          Let's talk about your project
        </h2>
      </div>
      <Formik
        initialValues={{
          firstName: formData.firstName ?? "",
          surname: formData.surname ?? "",
          email: formData.email ?? "",
          phone: formData.phone ?? "",
          consent: formData.consent ?? false,
        }}
        onSubmit={(values) => {
          const data = { ...formData, ...values };
          setFormData(data);
          setActiveStepIndex(activeStepIndex + 1);
        }}
      >
        <Form className="flex flex-col gap-4 w-full lg:h-full justify-center max-w-3xl mt-6 lg:mt-12 mx-auto">
          <Input name="firstName" label="First name" required />
          <Input name="surname" label="Surname" required />
          <Input name="email" label="Email address" type="email" required />
          <Input name="phone" label="Phone Number" required />
          <label className="flex gap-4 text-sm mt-2">
            <input type="checkbox" name="consent" required />
            By submitting this form, you are consenting to being contacted by
            Benlin Homes in future, on the understanding that you have read and
            agree to our <a href="/privacy-policy">Privacy Policy</a> and can
            opt out at any time.
          </label>
          <Pagination step="Contact Details" />
        </Form>
      </Formik>
    </>
  );
}

export default ContactDetails;

import { useContext } from "react";
import { FormContext } from "../App";
import { Formik, Form } from "formik";
import Heading from "../components/Heading";
import speech from "../../assets/speech.png";
import people from "../../assets/people.png";
import pencil from "../../assets/pencil.png";

const StartAProject = () => {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext);
  return (
    <>
      <Heading text="Start A Project" />
      <div className="grid lg:grid-cols-3 gap-16 lg:gap-6 my-auto">
        <div className="flex flex-col gap-4 lg:gap-12 justify-center items-center text-center">
          <img src={speech} />
          <span className="h4">Discuss your project over the phone</span>
          <a href="tel:0893799220" className="btn mx-auto">
            Click to call
          </a>
        </div>
        <div className="flex flex-col gap-4 lg:gap-12 justify-center items-center text-center">
          <img src={people} />
          <span className="h4">Meet with a designer about your project</span>
          <a href="/contact" className="btn mx-auto">
            Book meeting
          </a>
        </div>
        <Formik
          initialValues={{}}
          onSubmit={(values) => {
            const data = { ...formData, ...values };
            setFormData(data);
            setActiveStepIndex(activeStepIndex + 1);
          }}
        >
          <Form className="flex flex-col gap-4 lg:gap-12 justify-center items-center text-center">
            <img src={pencil} />
            <span className="h4">Use our guided design brief tool online</span>
            <button type="submit" className="bg-black mx-auto btn">
              Get started
            </button>
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default StartAProject;

import { Form, Formik } from "formik";
import React, { useContext } from "react";
import { FormContext } from "../App";
import RadioGroup from "../components/RadioGroup";
import Radio from "../components/Radio";
import Input from "../components/Input";
import Pagination from "../components/Pagination";
import Heading from "../components/Heading";

function External() {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext);

  return (
    <>
      <Heading />
      <Formik
        initialValues={{
          elevationPreferences: formData.elevationPreferences ?? "",
          alfrescoPreferences: formData.alfrescoPreferences ?? "",
          garagePreferences: formData.garagePreferences ?? "",
          workshop: formData.workshop ?? "Yes",
          outdoorKitchen: formData.outdoorKitchen ?? "Yes",
          swimmingPool: formData.swimmingPool ?? "Yes",
          balcony: formData.balcony ?? "Yes",
        }}
        onSubmit={(values) => {
          const data = { ...formData, ...values };
          setFormData(data);
          setActiveStepIndex(activeStepIndex + 1);
        }}
      >
        <Form className="flex flex-col gap-6 h-full justify-center w-full max-w-5xl">
          <Input
            label={"Preferences for elevation design"}
            name="elevationPreferences"
          />
          <Input
            label={"Preferences for alfresco design"}
            name="alfrescoPreferences"
          />
          <Input
            label={"Preferences for garage size and position"}
            name="garagePreferences"
          />
          <RadioGroup label={"Would you like a workshop?"}>
            <Radio name="workshop" value="Yes" />
            <Radio name="workshop" value="No" />
          </RadioGroup>
          <RadioGroup label={"Would you like an outdoor kitchen?"}>
            <Radio name="outdoorKitchen" value="Yes" />
            <Radio name="outdoorKitchen" value="No" />
          </RadioGroup>
          <RadioGroup label={"Will there be a swimming pool?"}>
            <Radio name="swimmingPool" value="Yes" />
            <Radio name="swimmingPool" value="No" />
          </RadioGroup>
          <RadioGroup label={"If two storey, would you like a balcony?"}>
            <Radio name="balcony" value="Yes" />
            <Radio name="balcony" value="No" />
          </RadioGroup>
          <Pagination step="External" />
        </Form>
      </Formik>
    </>
  );
}

export default External;

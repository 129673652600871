import React, { useContext } from "react";
import { FormContext } from "../App";
import StartAProject from "../steps/StartAProject";
import EssentialDetails from "../steps/EssentialDetails";
import Lifestyle from "../steps/Lifestyle";
import Family from "../steps/Family";
import DesignStyle from "../steps/DesignStyle";
import KitchenLivingDining from "../steps/KitchenLivingDining";
import Bedrooms from "../steps/Bedrooms";
import Bathrooms from "../steps/Bathrooms";
import AdditionalSpaces from "../steps/AdditionalSpaces";
import External from "../steps/External";
import Features from "../steps/Features";
import Location from "../steps/Location";
import Logistics from "../steps/Logistics";
import ContactDetails from "../steps/ContactDetails";
import ThankYou from "../steps/ThankYou";

function Step() {
  const { activeStepIndex } = useContext(FormContext);
  let stepContent;
  switch (activeStepIndex) {
    case 0:
      stepContent = <StartAProject />;
      break;
    case 1:
      stepContent = <EssentialDetails />;
      break;
    case 2:
      stepContent = <Lifestyle />;
      break;
    case 3:
      stepContent = <Family />;
      break;
    case 4:
      stepContent = <DesignStyle />;
      break;
    case 5:
      stepContent = <KitchenLivingDining />;
      break;
    case 6:
      stepContent = <Bedrooms />;
      break;
    case 7:
      stepContent = <Bathrooms />;
      break;
    case 8:
      stepContent = <AdditionalSpaces />;
      break;
    case 9:
      stepContent = <External />;
      break;
    case 10:
      stepContent = <Features />;
      break;
    case 11:
      stepContent = <Location />;
      break;
    case 12:
      stepContent = <Logistics />;
      break;
    case 13:
      stepContent = <ContactDetails />;
      break;
    case 14:
      stepContent = <ThankYou />;
      break;
  }

  return stepContent;
}

export default Step;

import { Form, Formik } from "formik";
import React, { useContext } from "react";
import { FormContext } from "../App";
import Input from "../components/Input";
import Pagination from "../components/Pagination";
import Heading from "../components/Heading";

function AdditionalSpaces() {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext);

  return (
    <>
      <Heading />
      <Formik
        initialValues={{
          officePreference: formData.officePreference ?? "",
          theatrePreference: formData.theatrePreference ?? "",
          gamesRoomPreference: formData.gamesRoomPreference ?? "",
          entryWayPreference: formData.entryWayPreference ?? "",
          stairsPreference: formData.stairsPreference ?? "",
          notesOnOtherSpaces: formData.notesOnOtherSpaces ?? "",
        }}
        onSubmit={(values) => {
          const data = { ...formData, ...values };
          setFormData(data);
          setActiveStepIndex(activeStepIndex + 1);
        }}
      >
        <Form className="flex flex-col gap-3 h-full justify-center w-full max-w-5xl">
          <Input
            label={"Preferences for office/study/library"}
            name="officePreference"
          />
          <Input
            label={"Preferences for home theatre"}
            name="theatrePreference"
          />
          <Input
            label={"Preferences for games room"}
            name="gamesRoomPreference"
          />
          <Input label={"Preferences for entryway"} name="entryWayPreference" />
          <Input
            label={"Preferences for stairs (if applicable)"}
            name="stairsPreference"
          />
          <Input
            label={
              "Notes on any other spaces (studio, nook, kids activity, etc)"
            }
            name="notesOnOtherSpaces"
          />
          <Pagination step="Additional Spaces" />
        </Form>
      </Formik>
    </>
  );
}

export default AdditionalSpaces;

import { Form, Formik } from "formik";
import React, { useContext } from "react";
import { FormContext } from "../App";
import Radio from "../components/Radio";
import Input from "../components/Input";
import Pagination from "../components/Pagination";
import Heading from "../components/Heading";

function Location() {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext);

  return (
    <>
      <Heading />
      <Formik
        initialValues={{
          blockSuburb: formData.blockSuburb ?? "",
          areaWidthDepth: formData.areaWidthDepth ?? "",
          frontRearAccess: formData.frontRearAccess ?? "",
          existingHomeOnthisSite: formData.existingHomeOnthisSite ?? "",
          lotFeatures: formData.lotFeatures ?? "",
          desiredSuburb: formData.desiredSuburb ?? "",
          landPreference: formData.landPreference ?? "Prefer lots of space",
        }}
        onSubmit={(values) => {
          const data = { ...formData, ...values };
          setFormData(data);
          setActiveStepIndex(activeStepIndex + 1);
        }}
      >
        <Form className="flex flex-col gap-12 lg:h-full w-full justify-center">
          <div className="grid gap-6 lg:grid-cols-2 lg:gap-12">
            <div className="flex flex-col gap-3">
              <strong className="text-xl underline lg:no-underline lg:text-base">
                If you already have land:
              </strong>
              <Input
                name="blockSuburb"
                label={"In which suburb is your block located?"}
              />
              <Input
                name="areaWidthDepth"
                label={"Area, width & depth of your block"}
              />
              <Input
                name="frontRearAccess"
                label={"Does your block have front access or rear access?"}
              />
              <Input
                name="existingHomeOnthisSite"
                label={"Is there an existing home on the site?"}
              />
              <Input
                name="lotFeatures"
                label={"Any features of the lot to be aware of?"}
              />
            </div>
            <div className="flex flex-col gap-4">
              <strong className="text-xl underline lg:no-underline lg:text-base">
                If you havent't selected a block yet:
              </strong>
              <Input
                name="desiredSuburb"
                label={"Which suburb would you like to live in?"}
              />
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-1">
                  <Radio name="landPreference" value="Prefer lots of space" />
                  <Radio
                    name="landPreference"
                    value="Prefer lower maintenance"
                  />
                  <Radio name="landPreference" value="Prefer a rural setting" />
                  <Radio
                    name="landPreference"
                    value="Prefer being close to amenities"
                  />
                </div>
              </div>
            </div>
          </div>
          <Pagination step="Location" />
        </Form>
      </Formik>
    </>
  );
}

export default Location;

import { Form, Formik } from "formik";
import React, { useContext } from "react";
import { FormContext } from "../App";
import RadioGroup from "../components/RadioGroup";
import Radio from "../components/Radio";
import Pagination from "../components/Pagination";
import Heading from "../components/Heading";

function KitchenLivingDining() {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext);

  return (
    <>
      <Heading />
      <Formik
        initialValues={{
          kitchenPreference: formData.kitchenPreference ?? "Open plan design",
          kitchenLayout: formData.kitchenLayout ?? "Galley",
          pantryStyle: formData.pantryStyle ?? "Scullery",
          cellarOrBar: formData.cellarOrBar ?? "Cellar",
        }}
        onSubmit={(values) => {
          const data = { ...formData, ...values };
          setFormData(data);
          setActiveStepIndex(activeStepIndex + 1);
        }}
      >
        <Form className="flex flex-col gap-6 h-full justify-center">
          <RadioGroup label={"Which do you prefer?"}>
            <Radio name="kitchenPreference" value="Open plan design" />
            <Radio
              name="kitchenPreference"
              value="Formal living and dining spaces"
            />
          </RadioGroup>
          <RadioGroup label={"Choose you preferred kitchen layout style:"}>
            <Radio name="kitchenLayout" value="Galley" />
            <Radio name="kitchenLayout" value="U-shaped" />
            <Radio name="kitchenLayout" value="Island" />
          </RadioGroup>
          <RadioGroup label={"Would you like a:"}>
            <Radio name="pantryStyle" value="Scullery" />
            <Radio name="pantryStyle" value="Butlers pantry" />
          </RadioGroup>
          <RadioGroup label={"Would you like a wine cellar or bar?"}>
            <Radio name="cellarOrBar" value="Cellar" />
            <Radio name="cellarOrBar" value="Bar" />
            <Radio name="cellarOrBar" value="Both" />
          </RadioGroup>
          <Pagination step="Kitchen/Living/Dining" />
        </Form>
      </Formik>
    </>
  );
}

export default KitchenLivingDining;

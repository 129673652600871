import { Form, Formik } from "formik";
import React, { useContext } from "react";
import { FormContext } from "../App";
import RadioGroup from "../components/RadioGroup";
import Radio from "../components/Radio";
import Input from "../components/Input";
import Pagination from "../components/Pagination";
import Heading from "../components/Heading";

function Lifestyle() {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext);

  return (
    <>
      <Heading />
      <Formik
        initialValues={{
          entertainmentType:
            formData.entertainmentType ?? "Entertaining large groups",
          entertainmentSpaces: formData.entertainmentSpaces ?? "Indoors",
          lifestyleSpaces: formData.lifestyleSpaces ?? "Kids activity",
          lifestyleOther: formData.lifestyleOther ?? "",
        }}
        onSubmit={(values) => {
          const data = { ...formData, ...values };
          setFormData(data);
          setActiveStepIndex(activeStepIndex + 1);
        }}
      >
        <Form className="flex flex-col gap-6 h-full justify-center">
          <RadioGroup
            label={"Which of these best describes the way you entertain?"}
          >
            <Radio name="entertainmentType" value="Entertaining large groups" />
            <Radio name="entertainmentType" value="Hosting dinner parties" />
            <Radio name="entertainmentType" value="Rarely entertain" />
          </RadioGroup>
          <RadioGroup label={"Which spaces do you use for entertaining?"}>
            <Radio name="entertainmentSpaces" value="Indoors" />
            <Radio name="entertainmentSpaces" value="Outdoors" />
            <Radio name="entertainmentSpaces" value="Both" />
          </RadioGroup>
          <RadioGroup
            label={"Which additional lifestyle spaces would you like?"}
          >
            <Radio
              type="checkbox"
              name="lifestyleSpaces"
              value="Kids activity"
            />
            <Radio type="checkbox" name="lifestyleSpaces" value="Theatre" />
            <Radio type="checkbox" name="lifestyleSpaces" value="Games room" />
            <Radio type="checkbox" name="lifestyleSpaces" value="Nursery" />
          </RadioGroup>
          <Input label={"Other"} name="lifestyleOther" />
          <Pagination step="Lifestyle" />
        </Form>
      </Formik>
    </>
  );
}

export default Lifestyle;

import { Field } from "formik";

const Input = ({ label, name, type = "text", ...props }) => {
  return (
    <label className="flex flex-col gap-3 px-4 w-full lg:flex-row lg:px-0 lg:gap-6 lg:items-end">
      <span className="flex-shrink-0 font-bold lg:font-normal">{label}</span>
      <Field
        name={name}
        type={type}
        className="w-full border-t-0 border-l-0 border-r-0 border-b border-black pb-px"
        {...props}
      />
    </label>
  );
};

export default Input;

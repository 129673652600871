import { Form, Formik } from "formik";
import React, { useContext } from "react";
import { FormContext } from "../App";
import RadioGroup from "../components/RadioGroup";
import Radio from "../components/Radio";
import Input from "../components/Input";
import Pagination from "../components/Pagination";
import Heading from "../components/Heading";

function Family() {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext);

  return (
    <>
      <Heading />
      <Formik
        initialValues={{
          peopleCount: formData.peopleCount ?? "",
          petConsiderations: formData.petConsiderations ?? "Pet doors",
          guestFrequency: formData.guestFrequency ?? "Often",
        }}
        onSubmit={(values) => {
          const data = { ...formData, ...values };
          setFormData(data);
          setActiveStepIndex(activeStepIndex + 1);
        }}
      >
        <Form className="flex flex-col gap-6 h-full justify-center">
          <Input
            label={"How many people will be living in this home?"}
            name="peopleCount"
            type="number"
          />
          <RadioGroup label={"Do you have pets? Any considerations for them?"}>
            <Radio type="checkbox" name="petConsiderations" value="Pet doors" />
            <Radio type="checkbox" name="petConsiderations" value="Mud room" />
            <Radio
              type="checkbox"
              name="petConsiderations"
              value="Backyard space/access"
            />
          </RadioGroup>
          <RadioGroup label={"Do you have guests stay with you in your home?"}>
            <Radio name="guestFrequency" value="Often" />
            <Radio name="guestFrequency" value="Occasionally" />
            <Radio name="guestFrequency" value="Rarely" />
            <Radio name="guestFrequency" value="Never" />
          </RadioGroup>
          <Pagination step="Family" />
        </Form>
      </Formik>
    </>
  );
}

export default Family;

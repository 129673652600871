import { Form, Formik } from "formik";
import React, { useContext } from "react";
import { FormContext } from "../App";
import RadioGroup from "../components/RadioGroup";
import Radio from "../components/Radio";
import Input from "../components/Input";
import Pagination from "../components/Pagination";
import Heading from "../components/Heading";

function EssentialDetails() {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext);

  return (
    <>
      <Heading />
      <Formik
        initialValues={{
          bedrooms: formData.bedrooms ?? "3",
          bathrooms: formData.bathrooms ?? "2",
          storey: formData.storey ?? "Single storey",
          size: formData.size ?? "<300sqm",
          reason: formData.reason ?? "",
        }}
        onSubmit={(values) => {
          const data = { ...formData, ...values };
          setFormData(data);
          setActiveStepIndex(activeStepIndex + 1);
        }}
      >
        <Form className="flex flex-col gap-6 lg:h-full justify-center">
          <RadioGroup label={"How many bedrooms would you like?"}>
            <Radio name="bedrooms" value={"3"} />
            <Radio name="bedrooms" value={"4"} />
            <Radio name="bedrooms" value={"5"} />
            <Radio name="bedrooms" value={"6"} />
            <Radio name="bedrooms" value={"7"} />
          </RadioGroup>
          <RadioGroup label={"How many bathrooms would you like?"}>
            <Radio name="bathrooms" value={"2"} />
            <Radio name="bathrooms" value={"3"} />
            <Radio name="bathrooms" value={"4"} />
            <Radio name="bathrooms" value={"5"} />
          </RadioGroup>
          <RadioGroup
            label={"Would you prefer a single story or double storey home?"}
          >
            <Radio name="storey" value="Single storey" />
            <Radio name="storey" value="Double storey" />
          </RadioGroup>
          <RadioGroup label={"What size of home are you envisioning?"}>
            <Radio name="size" value="<300sqm" />
            <Radio name="size" value="300-400sqm" />
            <Radio name="size" value="400-500sqm" />
            <Radio name="size" value=">500sqm" />
          </RadioGroup>
          <Input
            label={"What is your reason for building a new home?"}
            name="reason"
            required
          />
          <Pagination step="Essential Details" />
        </Form>
      </Formik>
    </>
  );
}

export default EssentialDetails;

import { Form, Formik } from "formik";
import React, { useContext } from "react";
import { FormContext } from "../App";
import Input from "../components/Input";
import Pagination from "../components/Pagination";
import Heading from "../components/Heading";

function Bedrooms() {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext);

  return (
    <>
      <Heading />
      <Formik
        initialValues={{
          masterBedroomLayout: formData.masterBedroomLayout ?? "",
          masterWardrobeLayout: formData.masterWardrobeLayout ?? "",
          minorBedroomLayout: formData.minorBedroomLayout ?? "",
          minorWardrobeLayout: formData.minorWardrobeLayout ?? "",
          guestSuite: formData.guestSuite ?? "",
        }}
        onSubmit={(values) => {
          const data = { ...formData, ...values };
          setFormData(data);
          setActiveStepIndex(activeStepIndex + 1);
        }}
      >
        <Form className="flex flex-col gap-6 h-full justify-center w-full max-w-5xl">
          <Input
            label={"Preferences for sizing and layout of master suite"}
            name="masterBedroomLayout"
          />
          <Input
            label={"Preferences for master wardrobe/dressing room"}
            name="masterWardrobeLayout"
          />
          <Input
            label={"Preferences for sizing and layout of minor bedrooms"}
            name="minorBedroomLayout"
          />
          <Input
            label={"Preferences for minor bedroom wardrobes"}
            name="minorWardrobeLayout"
          />
          <Input
            label={"Will one of the bedrooms be part of a guest suite?"}
            name="guestSuite"
          />
          <Pagination step="Bedrooms" />
        </Form>
      </Formik>
    </>
  );
}

export default Bedrooms;
